import React, { useEffect, useState } from "react";
import { Alert } from "../../components/dashboardNew/alert/Alert";
import { Insurance } from "../../components/dashboardNew/insurance/Insurance";
import { MyFamily } from "../../components/dashboardNew/myfamily/MyFamily";
import { CancelledAppointments } from "../../components/dashboardNew/myschedule/CancelledAppointments";
import { CompletedAppointments } from "../../components/dashboardNew/myschedule/CompletedAppointments";
import { UpcomingAppointments } from "../../components/dashboardNew/myschedule/UpcomingAppointments";
import { MyOffers } from "../../components/dashboardNew/referral/Referral";
import { AdSlider } from "../../components/dashboardNew/slider/AdSlider";
import { Speciality } from "../../components/dashboardNew/speciality/Speciality";
import { XcarePoints } from "../../components/dashboardNew/xcarepoints/XcarePoints";
import {
  fetchLoadingPending,
  fetchLoadingSuccess,
} from "../../../store/loadingIndicator/actions";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { fetchAllAppointment } from "../../services/appointment";
import { AppointmentsDrawer } from "../../components/dashboardNew/myschedule/AppointmentsDrawer";
import { SharedOffCanvas } from "../../components/sharedOffCanvas/SharedOffCanvas";
import {
  showWaitingPayment,
  showWaitingPresc,
  userLogout,
} from "../../../store/auth/actions";
import { useLocation } from "react-router";
import { PrescreeningModal } from "../../components/appointments/PrescreeningModal";
import { getCookie } from "../../utils/cookies";
import { PrescreeningModalForPastAppointment } from "../../components/appointments/PrescreeningModalForPastAppointment";
import * as firebase from "firebase/app";
import "firebase/firestore";
import configs from "../../../configs/apiConfigs";
import { CHAT } from "../../../constants";
import * as _ from "lodash";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
let listener: any;
let appListener: any;
export const DashboardNew = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [appointmentList, setAppointmentList]: any = useState([]);
  const [isNoRecord, setIsNoRecord] = useState(false);
  const [isShowAppointmentDrawer, setIsShowAppointmentDrawer] = useState(false);
  const [drawerType, setDrawerType] = useState("upcoming");
  const patientData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );
  const [completeScreeningModal, setCompleteScreeningModal] = useState(false);
  const [currentSelectedAppointment, setCurrentSelectedAppointment]: any =
    useState("");
  const location: any = useLocation();
  const [showAlert, setShowAlert] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [medium, setMedium]: any = useState("");
  const [mode, setMode]: any = useState("");
  const [
    isShowPastAppointmentPrescreeningModal,
    setIsShowPastAppointmentPrescreeningModal,
  ] = useState(false);
  const [isShowPoints, setIsShowPoints] = useState(false);
  const [isShowInsurance, setIsShowInsurance] = useState(false);

  useEffect(() => {
    console.log("isShowPoints", isShowPoints);
  }, [isShowPoints]);
  useEffect(() => {
    // fetchAppointmentApi();
    if (
      patientData &&
      patientData.dependant &&
      patientData.dependant[0] &&
      patientData.dependant[0].uniqueId
    ) {
      console.log("callAPI");
      if (listener) {
        listener();
      }
      // if (appListener) {
      //     appListener();
      // }
      const dependantList = patientData.dependant.map((item: any) => {
        return Number(item.uniqueId);
      });
      let isAddedNewDoc: boolean = false;
      let onLoad: any = false;
      listener = firebase
        .firestore()
        .collection(configs.CHAT_COLLECTION_NAME)
        .where("actionType", "in", [
          CHAT.ACTION_TYPE.STATUS,
          CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT,
          CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT,
          CHAT.ACTION_TYPE.PRESCREENING_STATUS,
          CHAT.ACTION_TYPE.CHECKIN,
        ])
        // .where("dependantId", "==", Number(patientData.dependant[0].uniqueId))
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change: any) => {
            isAddedNewDoc = false;
            let dependantId = change.doc.data().dependantId;
            if (dependantList.includes(dependantId)) {
              if (change.type === "added" && onLoad) {
                isAddedNewDoc = true;
              } else if (change.type === "modified") {
                // isAddedNewDoc = true;
              }
            }
          });
          onLoad = true;
          if (isAddedNewDoc && onLoad) {
            setTimeout(() => {
              fetchAppointmentApi();
            }, 4000);
          }
          // fetchAppointmentApi();
        });

      fetchAppointmentApi();
      // appListener = firebase.firestore().collection(configs.CHAT_COLLECTION_NAME)
      //     .where('actionType', 'in', [
      //         CHAT.ACTION_TYPE.PRESCREENING, CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT, CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT, CHAT.ACTION_TYPE.PRESCREENING_STATUS,
      //         CHAT.ACTION_TYPE.CHECKIN])
      //     .where("dependantId", "==", Number(patientData.dependant[0].uniqueId))
      //     .onSnapshot((snapshot) => {
      //         console.log('CallAPI2');
      //         snapshot.docChanges().forEach((change) => {
      //             if (change.type === "added") {
      //                 console.log('Added-AppListener');
      //             } else if (change.type === "modified") {
      //                 console.log('Modified-AppListener');
      //             }
      //         })
      //         // fetchAppointmentApi();
      //     });
    }
  }, []);

  const handleRescheduleAppointment = async (appointmentList: any) => {
    if (
      appointmentList &&
      appointmentList.upcoming &&
      appointmentList.upcoming.length > 0
    ) {
      let appointmentIds: any = appointmentList.upcoming.filter(
        (appointment: any) => {
          if (
            appointment &&
            appointment.metadata &&
            appointment.metadata.appointment &&
            appointment.metadata.appointment.confirmation &&
            appointment.metadata.appointment.confirmation.status === "cancelled"
          ) {
            return appointment.uniqueid;
          } else {
            return;
          }
        }
      );

      let appointmentListTemp: any = [];
      let appointmentsDateTime: any = [];
      if (appointmentIds && appointmentIds.length > 0) {
        for (let i = 0; i < appointmentIds.length; i++) {
          await firebase
            .firestore()
            .collection(configs.CHAT_COLLECTION_NAME)
            .where("appointmentId", "==", Number(appointmentIds[i].uniqueid))
            .where("actionType", "==", CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT)
            .orderBy("createdAt", "desc")
            .limit(1)
            .get()
            .then(async (querySnapshot: any) => {
              const datas = querySnapshot.docs.map((doc: any) => doc.data());
              appointmentsDateTime.push({
                uniqueid: appointmentIds[i].uniqueid,
                reschedule: datas[0].dateTime,
              });
              // appointmentListTemp = await
              //     appointmentList.upcoming.map((appointment: any) => {
              //         if (Number(appointment.uniqueid) === datas[0].appointmentId) {
              //             return { ...appointment, reschedule: datas[0].dateTime }
              //         } else {
              //             return appointment;
              //         }
              //     });
            });
        }
        let updatedApp: any[];
        updatedApp = appointmentList.upcoming.map((appointment: any) => {
          let findApp = _.find(appointmentsDateTime, {
            uniqueid: appointment.uniqueid,
          });
          if (findApp) {
            return {
              ...appointment,
              reschedule: findApp.reschedule,
            };
          } else {
            return appointment;
          }
        });
        setAppointmentList({
          cancelled: appointmentList.cancelled,
          completed: appointmentList.completed,
          upcoming: updatedApp,
        });
      } else {
        setAppointmentList(appointmentList);
      }
    } else {
      setAppointmentList(appointmentList);
    }
  };

  const fetchAppointmentApi = () => {
    setIsLoading(true);
    dispatch(fetchLoadingPending());
    fetchAllAppointment(patientData.uniqueId)
      .then((success) => {
        setIsLoading(false);
        dispatch(fetchLoadingSuccess(false));
        console.log("AppointmentApi", success);

        if (success && success.status && success.status.error === false) {
          console.log("setAppointmentData", success);
          setAppointmentList(success);
          handleRescheduleAppointment(success);

          // setAppointmentList(success);
          if (success.appointments && success.appointments.length <= 0) {
            setIsNoRecord(true);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        dispatch(fetchLoadingSuccess(false));
      });
  };
  useEffect(() => {
    if (
      appointmentList &&
      new URLSearchParams(location.search) &&
      new URLSearchParams(location.search).get("id") !== ""
    ) {
      let Id = new URLSearchParams(location.search).get("id");
      let mediumTemp = new URLSearchParams(location.search).get("medium");
      let mode = new URLSearchParams(location.search).get("mode");
      setMedium(mediumTemp);
      setMode(mode);
      const selectedAppointment = appointmentList?.upcoming?.find(
        (item: any) => item.uniqueid == Id
      );
      const selectedAppointmentCompleted = appointmentList?.completed?.find(
        (item: any) => item.uniqueid == Id
      );
      if (
        selectedAppointment &&
        selectedAppointment.metadata &&
        selectedAppointment.metadata.appointment &&
        new URLSearchParams(location.search).get("mode") === "prescreening"
      ) {
        dispatch(showWaitingPresc(false));
        console.log("called");
        setCompleteScreeningModal(true);
        setCurrentSelectedAppointment(
          appointmentList.upcoming.find((item: any) => item.uniqueid === Id)
        );
      } else if (
        selectedAppointmentCompleted &&
        selectedAppointmentCompleted.metadata &&
        selectedAppointmentCompleted.metadata.appointment &&
        new URLSearchParams(location.search).get("mode") === "payment"
      ) {
        dispatch(showWaitingPayment(false));
        setCurrentSelectedAppointment(
          appointmentList.completed.find((item: any) => item.uniqueid === Id)
        );
      } else if (
        appointmentList?.upcoming &&
        !selectedAppointment &&
        new URLSearchParams(location.search).get("mode") === "prescreening"
      ) {
        // <AlreadyPrescreeningVerifyModal isShow={true} handleClose={()=>{}}  />
        if (mediumTemp === "email" || mediumTemp === "sms") {
          console.log("called");
          setIsShowPastAppointmentPrescreeningModal(true);
        }
        dispatch(showWaitingPayment(false));
        dispatch(showWaitingPresc(false));
      }
    }
  }, [appointmentList]);
  const hadleShowAlert = () => {
    setShowAlert(true);
  };
  const handleHidePreScreeningModal = () => {
    if (medium === "email" || medium === "sms") {
      // executeWhenMediumEmailSms();
      setCurrentQuestion(0);
      // setCompleteScreeningModal(false);
    } else {
      setCurrentQuestion(0);
      setCompleteScreeningModal(false);
    }
  };
  const executeWhenMediumEmailSms = () => {
    // dispatch(userLogout());
    setCurrentQuestion(0);
    setCompleteScreeningModal(false);
    //  window.close()
  };
  const handleSuccessPreScreening = () => {
    if (medium === "email" || medium === "sms") {
      // executeWhenMediumEmailSms();
      setCurrentQuestion(0);
      setCompleteScreeningModal(false);
      const login: any = getCookie();
      dispatch(fetchLoadingSuccess(false));
      // fetchAppointmentApi();
    } else {
      setCurrentQuestion(0);
      setCompleteScreeningModal(false);
      const login: any = getCookie();
      dispatch(fetchLoadingSuccess(false));
      // fetchAppointmentApi();
    }
  };

  return (
    <>
      {isLoading && <LoadingIndicator />}
      <div className="v3-master">
        <div className="container-fluid container-xl px-lg-4">
          {/* <Alert /> */}
          <div className="row health_care_n_ad mb-4 d-flex justify-content-between d-none">
            <div className="col-8">
              <Speciality />
            </div>
            <div className="col-4 d-flex justify-content-end">
              <div className="xcare_ad">
                <AdSlider />
              </div>
            </div>
          </div>
          <div className="info_cards border-bottom mb-4 pb-4">
            <div className="row ">
              <div className="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0">
                <MyFamily />
              </div>
              <div className="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0 d-none d-sm-none d-md-block">
                <XcarePoints
                  isShowPoints={isShowPoints}
                  setIsShowPoints={setIsShowPoints}
                  setIsShowInsurance={setIsShowInsurance}
                />
              </div>
              <div className="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0">
                <Insurance
                  isShowInsurance={isShowInsurance}
                  setIsShowInsurance={setIsShowInsurance}
                />
              </div>
              <div className="col-12 col-lg-3 col-md-6 mb-3 mb-lg-0 d-none d-sm-none d-md-block">
                <MyOffers setIsShowPoints={setIsShowPoints} />
              </div>
            </div>
          </div>
          <div className="my_appointments mb-4">
            <div className="d-flex justify-content-between mb-3">
              <div className="fw-medium fs-5">My Schedule</div>
              <button
                type="button"
                className="btn btn-link p-0 fw-normal fs-7"
                onClick={() => {
                  setIsShowAppointmentDrawer(true);
                }}
              >
                View all
              </button>
            </div>
            <div className="appointments_cat">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0 upcomming_appo">
                  <button
                    className="btn btn-link mb-2 fs-5"
                    onClick={() => {
                      setIsShowAppointmentDrawer(true);
                      setDrawerType("upcoming");
                    }}
                  >
                    Upcoming Appointments
                  </button>
                  <UpcomingAppointments
                    appointments={appointmentList}
                    fromDashboard={true}
                    handleSuccess={() => {}}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0 complete_appo">
                  <button
                    className="btn btn-link mb-2 fs-5"
                    onClick={() => {
                      setIsShowAppointmentDrawer(true);
                      setDrawerType("completed");
                    }}
                  >
                    Completed Appointments
                  </button>
                  <CompletedAppointments
                    mode={mode}
                    selectedAppointment={currentSelectedAppointment}
                    appointments={appointmentList}
                    fromDashboard={true}
                    handleSuccess={() => {}}
                  />
                </div>
                <div className="col-12 col-md-6 col-lg-4 mb-4 mb-lg-0 cancelled_appo">
                  <button
                    className="btn btn-link mb-2 fs-5"
                    onClick={() => {
                      setIsShowAppointmentDrawer(true);
                      setDrawerType("cancelled");
                    }}
                  >
                    Cancelled Appointments
                  </button>
                  <CancelledAppointments
                    appointments={appointmentList}
                    fromDashboard={true}
                    handleSuccess={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* View all appointments drawer */}
        <SharedOffCanvas
          isOpen={isShowAppointmentDrawer}
          handleClose={() => {
            setIsShowAppointmentDrawer(false);
          }}
          Children={AppointmentsDrawer}
          handleSuccess={() => {
            setIsShowAppointmentDrawer(false);
            fetchAppointmentApi();
          }}
          appointmentList={appointmentList}
          drawerType={drawerType}
        />
        {completeScreeningModal && (
          <PrescreeningModal
            completeScreeningModal={completeScreeningModal}
            hadleShowAlert={hadleShowAlert}
            handleHidePreScreeningModal={handleHidePreScreeningModal}
            currentSelectedAppointment={currentSelectedAppointment}
            handleSuccessPreScreening={handleSuccessPreScreening}
          />
        )}
        {isShowPastAppointmentPrescreeningModal && (
          <PrescreeningModalForPastAppointment
            isShow={isShowPastAppointmentPrescreeningModal}
            handleClose={() => {
              setIsShowPastAppointmentPrescreeningModal(false);
            }}
            medium={medium}
          />
        )}
      </div>
    </>
  );
};
