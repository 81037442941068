import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { CancelledAppointments } from "./CancelledAppointments";
import { CompletedAppointments } from "./CompletedAppointments";
import { UpcomingAppointments } from "./UpcomingAppointments";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

export const AppointmentsDrawer = (props: any) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    console.log("drawerType", props.drawerType);
  }, [props.drawerType]);
  return (
    <div className="all_appo_drawer d-flex flex-column overflow-hidden">
      <div className="offcanvas-action d-flex">
        <button
          type="button"
          className="btn btn-link p-0 fw-normal fs-7 ms-auto"
          onClick={() => {
            props.handleModalClose();
          }}
        >
          Close
        </button>
      </div>

      <div className="offcanvas-header">
        <h5 className="offcanvas-title">My Appointments</h5>
      </div>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="upcoming"
        activeKey={props.drawerType}
      >
        <Nav className="cs_tab mb-3 border-bottom">
          <Nav.Item>
            <Nav.Link eventKey="upcoming">Upcoming</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="completed">Completed</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="cancelled">Cancelled</Nav.Link>
          </Nav.Item>
        </Nav>

        <div className="offcanvas-body p-3">
          <Tab.Content>
            <Tab.Pane eventKey="upcoming">
              <UpcomingAppointments
                appointments={props.appointmentList}
                fromDashboard={false}
                handleSuccess={() => {
                  props.handleSuccess();
                }}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="completed">
              <CompletedAppointments
                appointments={props.appointmentList}
                fromDashboard={false}
                handleSuccess={() => {
                  props.handleSuccess();
                }}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="cancelled">
              <CancelledAppointments
                appointments={props.appointmentList}
                fromDashboard={false}
                handleSuccess={() => {
                  props.handleSuccess();
                }}
              />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>

      {/* <Tabs
                defaultActiveKey="upcoming"
                transition={true}
                id="offcanvasTabs"
                className="cs_tab mb-3"
            >
                 
                    <Tab eventKey="upcoming" title="Upcoming">
                        <div className="offcanvas-body">
                            <UpcomingAppointments appointments={props.appointmentList} />
                        </div>
                    </Tab>
                    <Tab eventKey="completed" title="Completed">
                        <div className="offcanvas-body">
                            <CompletedAppointments appointments={props.appointmentList} />
                        </div>
                    </Tab>
                    <Tab eventKey="cancelled" title="Cancelled" >
                        <div className="offcanvas-body">
                            <CancelledAppointments appointments={props.appointmentList} />
                        </div>
                    </Tab>
                 

            </Tabs> */}
      {/* <div className="offcanvas-tabs mb-3">
                <ul className="nav nav-tabs cs_tab d-flex justify-content-between" id="myappointmentTab" role="tablist">
                    <li className="nav-item flex-fill" role="presentation">
                        <a className="tab-link active" id="upcoming-tab" data-bs-toggle="tab" data-bs-target="#upcoming" role="tab" aria-controls="upcoming" aria-selected="true">Upcoming</a>
                    </li>
                    <li className="nav-item flex-fill" role="presentation">
                        <a className="tab-link" id="completed-tab" data-bs-toggle="tab" data-bs-target="#completed" role="tab" aria-controls="completed" aria-selected="false">Completed</a>
                    </li>
                    <li className="nav-item flex-fill" role="presentation">
                        <a className="tab-link" id="cancelled-tab" data-bs-toggle="tab" data-bs-target="#cancelled" role="tab" aria-controls="cancelled" aria-selected="false">Cancelled</a>
                    </li>
                </ul>
            </div> */}
      {/* <div className="offcanvas-body">
                <div className="tab-content" id="myappointmentTabContent">
                    <div className="tab-pane fade show active" id="upcoming" role="tabpanel" aria-labelledby="upcoming-tab">
                        <UpcomingAppointments appointments={appointmentList} />
                    </div>
                    <div className="tab-pane fade" id="completed" role="tabpanel" aria-labelledby="completed-tab">
                        <CompletedAppointments appointments={appointmentList} />
                    </div>
                    <div className="tab-pane fade" id="cancelled" role="tabpanel" aria-labelledby="cancelled-tab">
                        <CancelledAppointments appointments={appointmentList} />
                    </div>
                </div>

            </div> */}
    </div>
  );
};
