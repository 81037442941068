import React, { useState } from 'react';
import { SharedOffCanvas } from '../../v2/components/sharedOffCanvas/SharedOffCanvas';
import { ReferFriend } from '../../v2/components/dashboardNew/xcarepoints/ReferFriend';

const Offers = () => {
    const [isShowReferFriend, setIsShowReferFriend] = useState(false);
    return (
        <>

            <div className="main-middle-sec">
                <div className="container upcoming-box">
                    <div className="row justify-content-center h-100">
                        <div className="col-md-6 align-self-center">
                            <div className="text-center">
                                <div className="pin-letter">                                     
                                    <img src={require('../../assets/images/announce.png')} className="img-responsive letter-icon mb-3" style={{
                                        maxHeight: 400
                                    }}/>
                                    <h3>Stay tuned, we will rollout <b>Offers</b> soon.</h3>
                                    <h6>
                                        More you can do now is <button type="button" className="btn btn-link p-0 fw-normal fs-7 me-3" onClick={() => { setIsShowReferFriend(true) }}>Refer a Friend</button>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SharedOffCanvas
                isOpen={isShowReferFriend}
                handleClose={() => { setIsShowReferFriend(false) }}
                Children={ReferFriend}
                handleSuccess={() => {
                    setIsShowReferFriend(false)
                }}
            />
        </>
    )
}
export default Offers;