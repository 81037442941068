import React, { useEffect, useMemo, useState } from "react";
import { SharedOffCanvas } from "../../sharedOffCanvas/SharedOffCanvas";
import { LoyaltyActivity } from "./LoyaltyActivity";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { joinLoyalty } from "../../../services/loyalty";
import { patientDataPending } from "../../../../store/patientData/actions";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { ManageEmail } from "./ManageEmail";

export const XcarePoints = (props: any) => {
  const [isShowReferFriend, setIsShowReferFriend] = useState(false);
  const [joinLoyaltyLoader, setJoinLoyaltyLoader] = useState(false);
  const [isAddEmail, setIsAddEmail] = useState(false);
  const patientData = useSelector(
    (state: any) => state.patientData.patientDataResponse,
    shallowEqual
  );

  useEffect(() => {
    setIsShowReferFriend(props.isShowPoints);
  }, [props.isShowPoints]);
  const dispatch = useDispatch();
  const currentDependant = useMemo(() => {
    const ID = patientData.id;
    return patientData?.dependant?.find(
      (item: any) => Number(item.loginId) === Number(ID)
    );
  }, [patientData]);
  const totalSum = useMemo(() => {
    let sum = 0;
    patientData?.dependant?.forEach((item: any) => {
      if (item.points && Number.isFinite(Number(item.points))) {
        sum += Number(item.points);
      }
    });
    return sum;
  }, [patientData]);
  const joinLoyaltyProgram = async () => {
    try {
      setJoinLoyaltyLoader(true);
      const success = await joinLoyalty(currentDependant?.id);
      setJoinLoyaltyLoader(false);
      if (success && success.status && success.status.error === false) {
        dispatch(patientDataPending({ uniqueId: patientData?.uniqueId }));
        toast.error(success?.status?.msg);
      }
    } catch (err) {
      setJoinLoyaltyLoader(false);
      toast.error(
        "We are unable to enrol you, please try again after sometime."
      );
    }
  };
  console.log("patientData", currentDependant);
  return (
    <>
      <div className="card_item rew_loy_point card p-3">
        <div className="infocard_header mb-2">
          <div className="card_ititle">
            My Rewards #{currentDependant?.memberId}
          </div>
        </div>
        <div className="infocard-body p-1">
          <div style={{ fontSize: "12px" }}>
            Email ID:{" "}
            {currentDependant?.memberEmail ? (
              currentDependant?.memberEmail
            ) : (
              <button
                type="button"
                className="btn btn-link p-0 fw-normal fs-7"
                onClick={() => {
                  setIsAddEmail(true);
                }}
              >
                Add Email <span style={{ color: "red" }}>*</span>
              </button>
            )}
          </div>
          <div className="av-txt pt-2">Points: {currentDependant?.points}</div>
        </div>
        <div className="card_ilink mt-5">
          {currentDependant?.memberId !== "0" &&
          currentDependant?.memberId !== "" &&
          currentDependant?.memberId ? (
            joinLoyaltyLoader ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <div className="card_ilink fix_bottom">
                <button
                  type="button"
                  className="btn btn-link p-0 fw-normal fs-7"
                  onClick={() => {
                    setIsShowReferFriend(true);
                  }}
                >
                  View points activity
                </button>
              </div>
            )
          ) : joinLoyaltyLoader ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <div className="card_ilink fix_bottom">
              <button
                type="button"
                className="btn btn-link p-0 fw-normal fs-7"
                onClick={() => {
                  joinLoyaltyProgram();
                }}
              >
                Join Xcare Rewards
              </button>
            </div>
          )}
        </div>
      </div>
      <SharedOffCanvas
        isOpen={isShowReferFriend}
        handleClose={() => {
          setIsShowReferFriend(false);
          props.setIsShowPoints(false);
        }}
        Children={LoyaltyActivity}
        handleSuccess={() => {
          setIsShowReferFriend(false);
          props.setIsShowPoints(false);
        }}
        setIsShowInsurance={props.setIsShowInsurance}
        style={{
          width: "70vw",
        }}
      />
      <ManageEmail
        isShow={isAddEmail}
        handleClose={() => {
          setIsAddEmail(false);
          window.location.reload();
        }}
      />
    </>
  );
};
