import React, { useState } from 'react';
import { Animated } from 'react-animated-css';
import { Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { useForm } from 'react-hook-form';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LABELS, TITLES_LABELS } from '../../../constants';
import { checkForgotpasswordAccount, forgotPassword } from '../../../services/auth';
import { emailValidation } from '../../utils/validators';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { useEffect } from 'react';
export const ForgotPassword = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setError, handleSubmit, setValue, getValues, errors, trigger, watch } = useForm();
    const [askFor, setAskFor] = useState("");
    const history = useHistory();
    const location = useLocation();
    const [isSingleAccount, setIsSingleAccount]: any = useState('');
    const [emailOrPhoneState, setEmailOrPhoneState]: any = useState('');

    const onClickForgotPassword = (data: any) => {
        console.log('call', isSingleAccount, data);
        let requestData: any = {
            modelType: 'patient'
        };
        if (isNaN(getValues('emailorphone'))) {
            requestData = {
                ...requestData,
                email: getValues('emailorphone'), mobile: getValues('phone') ? getValues('phone') : '', isSingleAccount: isSingleAccount
            }
        } else {
            requestData = {
                ...requestData,
                mobile: getValues('emailorphone'), email: getValues('email') ? getValues('email') : '', isSingleAccount: isSingleAccount
            };
        }
        let obj = {};
        if (isNaN(getValues('emailorphone'))) {
            obj = {
                ...obj,
                email: getValues('emailorphone'), phone: getValues('phone'), isSingleAccount: isSingleAccount
            }
        } else {
            obj = {
                ...obj,
                phone: getValues('emailorphone'), email: getValues('email'), isSingleAccount: isSingleAccount
            };
        }
        if (isSingleAccount !== "") {
            setIsLoading(true);
            forgotPassword(requestData).then((success) => {
                setIsLoading(false);
                if (success && success.status && success.status.error === false) {
                    const queryParam: any = new URLSearchParams(location.search);
                    toast.success(success.status.msg);
                    if (queryParam.get('redirect_url') && queryParam.get('redirect_url') !== null && queryParam.get('redirect_url') !== "") {
                        history.push({
                            pathname: '/forgot-password-otp/' + success.status.patientotpId + "?redirect_url=" + queryParam.get("redirect_url"),
                            state: obj
                        });
                    } else {
                        history.push({
                            pathname: '/forgot-password-otp/' + success.status.patientotpId,
                            state: obj
                        });
                    }
                }
            }).catch((err: any) => {
                console.log(err);
                setIsLoading(false);
                setError('emailorphone', { type: 'custom', message: err.msg ? err.msg : 'Something went wrong' });
            });
        }
    }

    const callForgotNew = (isSingleAccount: boolean) => {
        let requestData: any = {
            modelType: 'patient'
        };
        if (isNaN(emailOrPhoneState)) {
            requestData = {
                ...requestData,
                email: emailOrPhoneState, mobile: '', isSingleAccount: isSingleAccount
            }
        } else {
            requestData = {
                ...requestData,
                mobile: emailOrPhoneState, email: '', isSingleAccount: isSingleAccount
            };
        }
        let obj = {};
        if (isNaN(emailOrPhoneState)) {
            obj = {
                ...obj,
                email: emailOrPhoneState, isSingleAccount: isSingleAccount
            }
        } else {
            obj = {
                ...obj,
                phone: emailOrPhoneState, isSingleAccount: isSingleAccount
            };
        }
        setIsLoading(true);
        forgotPassword(requestData).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                const queryParam: any = new URLSearchParams(location.search);
                toast.success(success.status.msg);
                if (queryParam.get('redirect_url') && queryParam.get('redirect_url') !== null && queryParam.get('redirect_url') !== "") {
                    history.push({
                        pathname: '/forgot-password-otp/' + success.status.patientotpId + "?redirect_url=" + queryParam.get("redirect_url"),
                        state: obj
                    });
                } else {
                    history.push({
                        pathname: '/forgot-password-otp/' + success.status.patientotpId,
                        state: obj
                    });
                }
            }
        }).catch((err: any) => {
            console.log(err);
            setIsLoading(false);
            setError('emailorphone', { type: 'custom', message: err.msg ? err.msg : 'Something went wrong' });
        });
    }

    const emailOrPhoneBlur = async (e: any) => {
        try {
            const isValid = await trigger('emailorphone');
            if (isValid) {
                if (isNaN(getValues('emailorphone'))) {
                    if (!emailValidation(getValues('emailorphone'))) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid email' });
                        return;
                    }
                } else {
                    if (getValues('emailorphone').length > 10 || getValues('emailorphone').length < 10) {
                        setError('emailorphone', { type: 'custom', message: 'Invalid phone' });
                        return;
                    }
                }
                setIsLoading(true);
                checkForgotpasswordAccount({ login: getValues('emailorphone'), modelType: 'patient' }).then((success) => {
                    setIsLoading(false);
                    if (success && success.status && success.status.error === false) {
                        setAskFor(success.askFor);
                        setIsSingleAccount(success.isSingleAccount);
                        if (success.isSingleAccount) {
                            callForgotNew(success.isSingleAccount);
                        }
                    }
                }).catch((err) => {
                    setIsLoading(false);
                    console.log(err);
                    setError('emailorphone', { type: 'custom', message: err.msg });
                });
            }
        } catch (err) {
            console.log(err);
        }
    }
    useEffect(() => {
        setIsSingleAccount("");
        setEmailOrPhoneState(getValues('emailorphone'));
    }, [watch('emailorphone')])
    return (
        <div className="master_patientp">
            {/*  {
                isLoading &&
                <LoadingIndicator></LoadingIndicator>
            } */}
            <section className="login-page">
                <Helmet><title>{TITLES_LABELS.META.LOGIN}</title></Helmet>
                <div className="container">
                    <div className="row flex-center min-vh-100 py-5">
                        <div className="col-sm-8 col-md-6 col-lg-6 col-xl-5 col-xxl-4 m-auto">
                            <Animated animationIn="fadeIn" animationOut="zoomOut" animationInDuration={1000} isVisible={true}>
                                <div className="card shadow-sm">
                                    <div className="card-header bg-light px-5 py-3">
                                        <img src={require('../../../assets/images/logo-black.svg')} alt="logo" />
                                    </div>
                                    <div className="card-body p-5">
                                        <div className="mb-3">
                                            {LABELS.FORGOT_PASSWORD_DESC}
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label fw-medium">{LABELS.EMAILORPHONE}</label>
                                            <input type="text" disabled={isLoading} className="form-control" name="emailorphone" ref={register({ required: true })} onBlur={emailOrPhoneBlur} />
                                            {
                                                (errors && errors.emailorphone && errors.emailorphone.type === 'required') &&
                                                <div className="text-danger error">Email or phone is required.</div>
                                            }
                                            {
                                                (errors && errors.emailorphone && errors.emailorphone.type === 'custom') &&
                                                <div className="text-danger error">{errors.emailorphone.message}</div>
                                            }
                                        </div>
                                        {
                                            askFor === "email" &&
                                            <div className="mb-3">
                                                <p>
                                                    We have detected multiple accounts with this mobile number, please enter email associated with this account to forgot password.
                                                    In case if you do not have email associated with this account please directly hit button.
                                                </p>
                                                <label className="form-label fw-medium">{LABELS.EMAIL}</label>
                                                <input type="text" disabled={isLoading} className="form-control" name="email" ref={register({
                                                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                                                })} />
                                                {
                                                    (errors && errors.email && errors.email.type === 'pattern') &&
                                                    <div className="text-danger error">Email is invalid</div>
                                                }
                                            </div>
                                        }
                                        {
                                            askFor === "phone" &&
                                            <div className="mb-3">
                                                <p>
                                                    We have detected multiple accounts with this email, please enter mobile number associated with this account to forgot password.
                                                    In case if you do not have mobile number associated with this account please directly hit button.
                                                </p>
                                                <label className="form-label fw-medium">{LABELS.PHONE}</label>
                                                <input type="text" disabled={isLoading} className="form-control" name="phone" ref={register({
                                                    maxLength: 10, minLength: 10
                                                })} />
                                                {
                                                    (errors && errors.phone && errors.phone.type === 'maxLength') &&
                                                    <div className="text-danger error">Mobile number is invalid</div>
                                                }
                                                {
                                                    (errors && errors.phone && errors.phone.type === 'minLength') &&
                                                    <div className="text-danger error">Mobile number is invalid</div>
                                                }
                                            </div>
                                        }
                                        <div className="mt-3 bottom-links">
                                            <button className="btn btn-dark w-100" onClick={handleSubmit(onClickForgotPassword)}>
                                                {LABELS.BUTTON.SEND_RESET_LINK}
                                                <span>
                                                    <>
                                                        {
                                                            isLoading === true &&
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="ms-2"
                                                            />
                                                        }
                                                    </>
                                                </span>
                                            </button>
                                            <div className="mt-3 bottom-links">
                                                <a className="text-danger d-block text-end" href="javascript:void(0)" onClick={(e) => {
                                                    history.push('/login');
                                                }}>Back to sign in</a>
                                                {
                                                    !['patient.xcarepro.com', 'patient.us.xcarepro.com'].includes(window.location.hostname) &&
                                                    <div className="mt-4 text-center">Don't have account? <a href="javascript:void(0)" onClick={(e) => {
                                                        history.push('/register');
                                                    }}>{TITLES_LABELS.LINKS.REGISTER}</a>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Animated>
                        </div>
                        <div className="text-center fw-normal mt-3">Copyright © {new Date().getFullYear()} Medesphere Inc. All rights reserved.</div>
                    </div>
                </div>
            </section>

        </div>
    )
}